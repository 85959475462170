<template>
	<div class="relative my-12 flex items-center">
		<img class="w-1/5 absolute" src="../../assets/Pattren.svg" alt="" />
		<section
			data-aos="fade-left"
			class="container mx-auto  px-5 md:px-8 text-black text-center md:text-right tracking-wide leading-relaxed mb-16"
		>
			<div class="md:flex items-center justify-center mx-auto md:justify-between flex-wrap md:flex-nowrap">
				<div class="w-full md:w-1/2 my-8">
					<h1 class="text-xl font-bold mb-5 ">الجهات المستهدفة</h1>
					<p class=" text-servicesTextColor md:text-black">
						نقدم خدماتنا للعديد من المؤسسات التابعة للدولة او اصحاب العمل الخاص ، فريقنا جاهز لتلبية جميع
						المتطلبات الخاصة بالزبائن
					</p>
				</div>
				<ul class="w-1/2 mx-auto text-center md:text-right md:w-auto md:my-8">
					<li class="flex items-center my-2   ">
						<span class="block rounded-md bg-primary h-3 w-3 mx-2"></span
						><span class="w-11/12	">الحدائق العامة والخاصة</span>
					</li>
					<li class="flex items-center my-2 ">
						<span class="block rounded-md bg-primary h-3 w-3 mx-2"></span
						><span class="w-11/12	">الطائرات</span>
					</li>
					<li class="flex items-center my-2 ">
						<span class="block rounded-md bg-primary h-3 w-3 mx-2"></span
						><span class="w-11/12	">الشركات الخاصة والعامة</span>
					</li>
				</ul>
				<ul class="w-1/2 mb-8 text-center md:text-right mx-auto md:w-auto md:my-8">
					<li class="flex items-center my-2 ">
						<span class="block rounded-md bg-primary h-3 w-3 mx-2"></span
						><span class="w-11/12	">المقاهي والمطاعم</span>
					</li>
					<li class="flex items-center my-2 ">
						<span class="block rounded-md bg-primary h-3 w-3 mx-2"></span>
						<span class="w-11/12	">الحقول النفطية</span>
					</li>
					<li class="flex items-center my-2 ">
						<span class="block rounded-md bg-primary h-3 w-3 mx-2"></span
						><span class="w-11/12	">الأحداث والمناسبات</span>
					</li>
				</ul>
				<router-link
					:to="{ name: 'Services' }"
					class="mx-auto md:hidden  bg-primary text-white px-5 py-2 rounded-md"
				>
					والمزيد
				</router-link>
			</div>
		</section>
	</div>
</template>
